import React from 'react';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  defaultSrc: string;
}

const Image: React.FC<ImageProps> = ({ src, defaultSrc, ...props }) => {
  const [imgSrc, setImgSrc] = React.useState(src);

  const handleError = () => {
    setImgSrc(defaultSrc);
  };

  React.useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return <img src={imgSrc} onError={handleError} {...props} />;
};

export default Image;