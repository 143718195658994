// GoogleMapComponent.tsx
import React from 'react';
import { GoogleMap, LoadScript, useJsApiLoader } from '@react-google-maps/api';

export interface GoogleMapComponentProps {
  center: {
    lat: number;
    lng: number;
  };
}



const GoogleMapComponent: React.FC<GoogleMapComponentProps> = ({ center }) => {

  const mapStyles = {
    height: '250px',
    width: '100%'
  };

  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY??"",
    libraries: ['geometry', 'drawing'],
  });
  
  return (
    <>
    {isLoaded && <GoogleMap mapContainerStyle={mapStyles} zoom={15} center={center} />}
    </>
  );
};

export default GoogleMapComponent;