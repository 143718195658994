import React, { FC } from 'react';
import { Card, Col, Dropdown, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';


const TermsAndConditions: FC = () => {
  return (
    <>

<div>
    {/* <!-- breadcrumb --> */}
    <div className="breadcrumb-header justify-content-between">
      <div className="left-content">
        <span className="main-content-title mg-b-0 mg-b-lg-1">Terms and Conditions - Last Updated: April 11, 2023</span>
      </div>
    </div>
    {/* <!-- /breadcrumb --> */}

    {/* <!-- row --> */}
    <Row className="row-sm">
      <Col sm={12}lg={5}xl={4}xxl={3}>
        <Card className="custom-card">
        <img src="images/earth-mix.png" alt="" />
        </Card>
      </Col>
      <Col sm={12} lg={7} xl={8}xxl={9}>
        <div className="">
          <Link className="main-header-arrow" to="#" id="ChatBodyHide">
            <i className="icon ion-md-arrow-back"></i>
          </Link>
          <div className="main-content-body main-content-body-contacts card custom-card">
            <div className="main-contact-info-body p-4">
              <div>
                <h6>Terms of Use</h6>
                Welcome to Rytasoft Uptovisit site By accessing, browsing, or using our website, mobile applications, or any other online service provided by us (collectively, the "Services"), you are agreeing to comply with and be bound by the following terms and conditions ("Terms of Use"). Please review these Terms of Use carefully before using our Services. If you do not agree to these terms, you must not use our Services.
              </div>
              <div className="media-list pb-0">
                <div className="media">
                  <div className="media-body">
                    <div>
                      <label>Acceptance of Terms</label>{" "}
                      <span className="tx-medium">
                      By using the Services, you represent and warrant that you have the legal capacity and authority to enter into these Terms of Use. If you are under the age of 18, you may use our Services only with the involvement of a parent or legal guardian who agrees to be bound by these Terms of Use.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                    <div>
                      <label> Changes to the Terms of Use</label>{" "}
                      <span className="tx-medium">
                      We reserve the right to modify these Terms of Use at any time without prior notice. Any changes will be effective immediately upon posting the revised terms on our website or mobile applications. Your continued use of the Services constitutes acceptance of any changes to these Terms of Use.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                    <div>
                      <div className="tx-medium">
                      Privacy <br />
                      Your privacy is important to us. Please review our Privacy Policy, which outlines how we collect, use, and protect your personal information.

                      User Conduct <br />
                      As a user of our Services, you agree not to:

                      a. Post or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;
                      b. Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;
                      c. Upload, post or otherwise transmit any content that you do not have a right to transmit under any law or contractual or fiduciary relationships;
                      d. Violate any applicable local, state, national or international law;
                      e. Engage in any activity that is harmful or potentially harmful to our Services, infrastructure, or any other users of our Services.

                      Intellectual Property Rights <br />
                      All content provided on our Services, including but not limited to text, graphics, logos, icons, images, and software, is the property of ABC Travel Advisor Company or its content suppliers and is protected by applicable copyright and intellectual property laws. Any unauthorized use, reproduction, or distribution of such content is strictly prohibited.

                      Third-Party Links <br />
                      Our Services may contain links to third-party websites or services. We do not endorse or control these websites or services and are not responsible for their content, availability, or security. Your use of any third-party website or service is at your own risk and subject to the terms and conditions of such websites or services.

                      Disclaimer of Warranties <br />
                      Our Services are provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, or suitability of the Services for any purpose. To the fullest extent permissible by law, we disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.

                      Limitation of Liability <br />
                      In no event shall ABC Travel Advisor Company, its affiliates, directors, officers, employees, or agents be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Services, or any other claim related in any way to your use of our Services.

                      Indemnification <br />
                      You agree to indemnify, defend, and hold harmless ABC Travel Advisor Company, its affiliates, directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of our Services, your violation of these Terms of Use, or your infringement or violation of any rights of another party.

                      Governing Law and Jurisdiction <br />
                      These Terms of Use shall be governed by and construed in accordance with the laws of the country in which ABC Travel Advisor Company is incorporated, without regard to its conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located in said country to resolve any disputes arising out of or in connection with these Terms of Use or your use of our Services.

                      Termination <br />
                      We reserve the right to terminate or suspend your access to our Services, without notice, for any reason, including without limitation, your breach of these Terms of Use. Upon termination, your right to use our Services will immediately cease, and you must discontinue all use of the Services.

                      Severability <br />
                      If any provision of these Terms of Use is deemed invalid or unenforceable by a court of competent jurisdiction, the remaining provisions shall continue in full force and effect.

                      Waiver <br />
                      No waiver of any term or condition of these Terms of Use shall be deemed a further or continuing waiver of such term or condition or any other term or condition, and any failure by us to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.

                      Entire Agreement <br />
                      These Terms of Use, together with our Privacy Policy, constitute the entire agreement between you and ABC Travel Advisor Company regarding the use of our Services and supersede all prior agreements, understandings, and representations between us, whether written or oral, relating to the subject matter hereof.

                      Contact Information <br />
                      If you have any questions or concerns regarding these Terms of Use or our Services, please contact us at:
                      </div>
                    </div>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body">
                  Rytasoft Unit #154 7/8-14 Melverton Drive, Hallam, VIC 3803, Australia
                      Email: info@uptovisit.com
                      Phone: +61406766694
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
      </Col>
    </Row>
    {/* <!-- End Row --> */}
  </div>
    </>
  );
};

export default TermsAndConditions;