export interface CompanyInfo {
    address: string;
    email: string;
    phone: string;
    twitter: string;
    facebook:string;
    instagram:string;
    youtube:string;
    tiktok:string;
    webSite:string;
  }
  
  const companyInfo: CompanyInfo = require('./contact-details.json');
  
  export default companyInfo;