import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

export type Trait = 'Thinking' | 'Judging' | 'Introverted' | 'Sensing' | 'Feeling' | 'Perceiving' | 'Extraverted' | 'Intuitive';
export interface TestResult {
  onSelected: (result: Trait) => void;
  onRestart: () => void;
}

type Question = {
  id: number;
  text: string;
  textTrait: string;
  textOpposite: string;
  trait: Trait; // 'Introverted', 'Extraverted', etc.
  opposite: Trait; // the opposite of the above trait
};

const questions: Question[] = [
  { id: 1, text: 'Do you prefer to focus on', textTrait: 'The outer world', textOpposite: 'Your own inner world', trait: 'Extraverted', opposite: 'Introverted' },
  { id: 2, text: 'Do You prefer to', textTrait: 'Focus on the basic information you take in', textOpposite: 'Interpret and add meaning', trait: 'Sensing', opposite: 'Intuitive' },
  { id: 3, text: 'When making decisions, Do you prefer to', textTrait: 'First look at logic and consistency', textOpposite: 'First look at the people and special circumstances', trait: 'Thinking', opposite: 'Feeling' },
  { id: 4, text: 'In dealing with the outside world, do you prefer to', textTrait: 'Get things decided', textOpposite: 'Stay open to new information and options', trait: 'Judging', opposite: 'Perceiving' }
];

const MBTITest: React.FC<TestResult> = ({ onSelected, onRestart }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [traits, setTraits] = useState<Trait[]>([]);

  const handleAnswer = (answer: 'yes' | 'no') => {
    const question = questions[currentQuestionIndex];
    const trait = answer === 'yes' ? question.trait : question.opposite;
    setTraits([...traits, trait]);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    onSelected(trait);
  };

  if (currentQuestionIndex >= questions.length) {
    // All questions answered, you can calculate the MBTI type here using the traits state.
    return <Card>
      <Card.Body>
        <h5 className="card-title mb-3">Please hit the search button to see where in your life, you should visit.</h5>
        <button className='btn me-2 btn-primary' onClick={() => {
          onRestart();
          setCurrentQuestionIndex(0);
        }}>Restart</button>
      </Card.Body>
    </Card>
  }

  return (
    <Card>
      <Card.Body>
        <h5 className="card-title mb-3">{questions[currentQuestionIndex].text}</h5>
        <button className='btn me-2 btn-primary mb-1' onClick={() => handleAnswer('yes')}>{questions[currentQuestionIndex].textTrait} - {questions[currentQuestionIndex].trait}</button>
        <button className='btn me-2 btn-info mb-1' onClick={() => handleAnswer('no')}>{questions[currentQuestionIndex].textOpposite}- {questions[currentQuestionIndex].opposite}</button>
      </Card.Body>
    </Card>
  );
};

export default MBTITest;
