import React from 'react';
import { Button, Row, Col, Form, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import companyInfo from '../../../companyInfo';
import SubscriptionForm from '../../Elements/SubscriptionForm';

const UnderConstruction = () => (
  <div >
    <div className="cover-image">
      <div className="page">
        <div className="page-single">
          <div className="container">
            <Row>
              <div className="col mx-auto">
                <Row className=" py-4 justify-content-center">
                  <Col xl={5} className=" card-sigin-main ">
                    <div className="card-sigin">
                      <div className="">
                        <div>
                          <h2 className="tx-30 text-center">
                            This Page is under construction
                          </h2>
                          <p className="tx-12 text-muted text-center">
                            Subscribe to our newsletter to get notified when we launch this page! Thank You!
                          </p>

                          <SubscriptionForm />    

                          <div className="mt-4 d-flex text-center justify-content-center">
                          <Nav className="contact-info">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Facebook</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-3" href={companyInfo.facebook} target='_blank'><span><i className="fa-brands fa-facebook fa-2xl"></i></span></a>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Twitter</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-3" href={companyInfo.twitter} target='_blank'><span><i className="fab fa-twitter fa-2xl"></i></span></a>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Instagram</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-3" href={companyInfo.instagram} target='_blank'><span><i className="fab fa-instagram fa-2xl"></i></span></a>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Tiktok</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-3" href={companyInfo.tiktok}  target='_blank'><span><i className="fab fa-tiktok fa-2xl"></i></span></a>
                          </OverlayTrigger>
                        </Nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </div>
  </div>
);

UnderConstruction.propTypes = {};

UnderConstruction.defaultProps = {};

export default UnderConstruction;
