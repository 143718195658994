import React from 'react';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';

const Aboutus = () => (
  <div>
      {/* <!-- breadcrumb --> */}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">ABOUT US</span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="#">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              About Us
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {/* <!-- /breadcrumb --> */}
      {/* <!-- row ---> */}
      <div className="container">
        <Row className="about-main mb-5">
          <Col lg={8} md={8} sm={12} className="text-center">
            <h1 className="mb-3 font-weight-semibold tx-46">
              Up to visit
            </h1>
            <p className="leading-normal lead-1">
            Best classification of the world locations
            </p>
            <p className="leading-normal  tx-16">
            Looking for inspiration on where to go for your next adventure? Look no further! Our website is your ultimate resource for discovering the world's most breathtaking destinations. From the pristine beaches of the Caribbean to the majestic peaks of the Himalayas, our comprehensive guide features a curated selection of the best places to visit. Whether you're an avid traveler or simply seeking a new adventure, our website provides all the information you need to plan your next unforgettable trip.
            </p>
          </Col>
        </Row>
        <Row className="br-5">
          <p>
            <img
              src={require("../../../assets/img/aboutus.jpg")}
              className="br-5"
              alt=""
            />
          </p>
        </Row>
        <Row className="py-5 about-motto pt-0">
          <Col lg={8} md={8} sm={12} >
            <div className="text-justify">
              <div className="text-dark tx-26 font-weight-semibold">
                Our Goal
              </div>
              <p className="tx-14 mb-4">
              UptoVisit.com is dedicated to the mission of revolutionizing travel planning. Our goal is to empower travelers by connecting them with destinations that align perfectly with their personalities and moods. We believe that every journey is an opportunity for self-discovery, and our platform is designed to uncover those unique locations across the globe that resonate with who you are and how you feel. UptoVisit.com, where your travel aspirations meet the world's vibrant diversity. Discover with us, discover yourself!
              </p>
              <div>
                <div className="d-flex mb-4">
                  <div>
                  <i className="fas fa-person-sign fa-2xl"></i>
                  </div>
                  <div className="ms-4">
                    <h5>Personalisation</h5>
                    <p>
                    Our website uses sophisticated algorithms to offer personalized travel recommendations based on your unique preferences. From bustling cities to serene countrysides, we identify destinations that best match your personality and mood, ensuring that each trip you undertake becomes a journey of self-discovery
                    </p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div>
                  <i className="fad fa-user-friends fa-2xl"></i>
                  </div>
                  <div className="ms-4">
                    <h5>User-Friendly Interface</h5>
                    <p>
                    At UptoVisit.com, we believe that the journey should begin from the moment you start planning your trip. Our user-friendly interface ensures that travel planning is a pleasure, not a chore. With intuitive search features and easy-to-navigate pages, we strive to make your experience with us as enjoyable as your actual travels. Explore the world with UptoVisit.com, where your travel aspirations come to life.
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                  <i className="far fa-book fa-2xl"></i>
                  </div>
                  <div className="ms-4">
                    <h5>Comprehensive Information</h5>
                    <p>
                    We provide extensive, reliable information on destinations worldwide. This includes the cultural richness, natural beauty, and historical significance of each location. Our comprehensive database aims to equip you with all the knowledge you need to make an informed decision about your next adventure.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col lg={6} md={6} xl={3}  >
            <Card className=" bg-primary-transparent">
              <Card.Body>
                <div className="counter-status md-mb-0">
                  <div className="text-center mb-1">
                  <svg className="about-icons" enableBackground="new 0 0 24 24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#38cab3" d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg>
                  </div>
                  <div className="text-center">
                    <h2 className="counter mb-2">6</h2>
                    <h6 className="mb-0 text-muted">Continents</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} xl={3}  >
            <Card className=" bg-secondary-transparent">
              <Card.Body>
                <div className="counter-status md-mb-0">
                  <div className="text-center mb-1">
                  <svg className="about-icons" enableBackground="new 0 0 24 24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(247, 79, 117, 0.8)" d="M336.174 80c-49.132 0-93.305-32-161.913-32-31.301 0-58.303 6.482-80.721 15.168a48.04 48.04 0 0 0 2.142-20.727C93.067 19.575 74.167 1.594 51.201.104 23.242-1.71 0 20.431 0 48c0 17.764 9.657 33.262 24 41.562V496c0 8.837 7.163 16 16 16h16c8.837 0 16-7.163 16-16v-83.443C109.869 395.28 143.259 384 199.826 384c49.132 0 93.305 32 161.913 32 58.479 0 101.972-22.617 128.548-39.981C503.846 367.161 512 352.051 512 335.855V95.937c0-34.459-35.264-57.768-66.904-44.117C409.193 67.309 371.641 80 336.174 80zM464 336c-21.783 15.412-60.824 32-102.261 32-59.945 0-102.002-32-161.913-32-43.361 0-96.379 9.403-127.826 24V128c21.784-15.412 60.824-32 102.261-32 59.945 0 102.002 32 161.913 32 43.271 0 96.32-17.366 127.826-32v240z"/></svg>
                  </div>
                  <div className="text-center mb-1">
                    <h2 className="counter mb-2">169</h2>
                    <h6 className="mb-0 text-muted">Countries</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} xl={3}  >
            <Card className=" bg-warning-transparent">
              <Card.Body>
                <div className="counter-status md-mb-0">
                  <div className="text-center mb-1">
                  <svg className="about-icons" enableBackground="new 0 0 24 24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(255, 189, 90, 0.8)" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/></svg>
                  </div>
                  <div className="text-center mb-1">
                    <h2 className="counter mb-2">1960</h2>
                    <h6 className="mb-0 text-muted">Destinations</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} md={6} xl={3}  >
            <Card className=" bg-info-transparent">
              <Card.Body>
                <div className="counter-status md-mb-0">
                  <div className="text-center mb-1">
                  <svg className="about-icons" enableBackground="new 0 0 24 24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="rgba(78, 194, 240, 0.8)" d="M164.07 148.1H12a12 12 0 0 1-12-12v-80a36 36 0 0 1 36-36h104a36 36 0 0 1 36 36v80a11.89 11.89 0 0 1-11.93 12zm347.93-12V56a36 36 0 0 0-36-36H372a36 36 0 0 0-36 36v80a12 12 0 0 0 12 12h152a11.89 11.89 0 0 0 12-11.9zm-164 44a12 12 0 0 0-12 12v52c0 128.1-160 127.9-160 0v-52a12 12 0 0 0-12-12H12.1a12 12 0 0 0-12 12.1c.1 21.4.6 40.3 0 53.3 0 150.6 136.17 246.6 256.75 246.6s255-96 255-246.7c-.6-12.8-.2-33 0-53.2a12 12 0 0 0-12-12.1z"/></svg>
                  </div>
                  <div className="text-center mb-1">
                    <h2 className="counter mb-2">11</h2>
                    <h6 className="mb-0 text-muted">Attractions</h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      {/* <!-- /row ---> */}
    </div>
);

Aboutus.propTypes = {};

Aboutus.defaultProps = {};

export default Aboutus;
