import React, { FC } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const TermsAndConditions: FC = () => {
  return (
    <>

      <div>
        {/* <!-- breadcrumb --> */}
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">Terms and Conditions - Last Updated: April 11, 2023</span>
          </div>
        </div>
        {/* <!-- /breadcrumb --> */}

        {/* <!-- row --> */}
        <Row className="row-sm">
          <Col sm={12} lg={5} xl={4} xxl={3}>
            <Card className="custom-card">
              <img src="images/map-earth.png" alt="" />
            </Card>
          </Col>
          <Col sm={12} lg={7} xl={8} xxl={9}>
            <div className="">
              <Link className="main-header-arrow" to="#" id="ChatBodyHide">
                <i className="icon ion-md-arrow-back"></i>
              </Link>
              <div className="main-content-body main-content-body-contacts card custom-card">
                <div className="main-contact-info-body p-4">
                  <h4>Rytasoft - Uptovisit Privacy Environmental Policy</h4>
                  <div className="media-list pb-0">
                    <div className="media">
                      <div className="media-body">
                        <div>
                          <div className="tx-medium">


                            <h5>I. Introduction</h5>
                            <p>At Rytasoft, we are committed to protecting the privacy of our clients, employees, and stakeholders while contributing to the sustainable development of the environment. This Privacy Environmental Policy outlines our approach to ensuring the responsible use of personal data and our dedication to environmental preservation in our daily operations as a travel advisor company.</p>

                            <h5>II. Privacy Policy</h5>
                            <h4>1. Personal Data Collection and Use</h4>
                            <ul>
                              <li>a. We collect and process personal information only for legitimate business purposes, such as providing travel advisory services, marketing, and improving our products and services. The types of personal data we collect may include names, contact information, travel preferences, and other information necessary for our services.</li>
                              <li>b. We use personal information in compliance with applicable privacy laws and regulations, and we will obtain consent from individuals before collecting, using, or disclosing their personal data.</li>
                            </ul>

                            <h4>2. Data Protection</h4>
                            <ul>
                              <li>a. We implement appropriate technical and organizational measures to safeguard personal data from unauthorized access, disclosure, alteration, or destruction.</li>
                              <li>b. We restrict access to personal data to our employees and third-party service providers on a need-to-know basis and ensure that they adhere to strict confidentiality and privacy obligations.</li>
                              <li>c. We regularly review and update our security measures to address new threats and vulnerabilities.</li>
                            </ul>

                            <h4>3. Third-Party Disclosure</h4>
                            <ul>
                              <li>a. We do not sell or rent personal information to third parties for marketing purposes without explicit consent.</li>
                              <li>b. We may share personal data with trusted third-party service providers, such as airlines, hotels, or tour operators, to facilitate travel arrangements. We ensure that these partners have adequate privacy and security measures in place.</li>
                            </ul>

                            <h4>4. Data Retention and Deletion</h4>
                            <ul>
                              <li>a. We retain personal data only for as long as necessary to fulfill the purposes for which it was collected, or as required by law.</li>
                              <li>b. We delete personal data when it is no longer needed, and we implement secure disposal methods to prevent unauthorized access or disclosure.</li>
                            </ul>

                            <h4>5. Individual Rights</h4>
                            <ul>
                              <li>a. We respect the rights of individuals to access, correct, or delete their personal data, and we provide appropriate mechanisms to facilitate these requests.</li>
                              <li>b. We comply with all applicable privacy laws and regulations and cooperate with data protection authorities to address any concerns or complaints.</li>
                            </ul>

                            <h5>III. Environmental Policy</h5>
                            <h4>1. Sustainable Operations</h4>
                            <ul>
                              <li>a. We strive to minimize the environmental impact of our business operations, such as energy consumption, waste generation, and greenhouse gas emissions.</li>
                              <li>b. We regularly assess our environmental performance and implement continuous improvement initiatives to reduce our ecological footprint.</li>
                            </ul>
                            <h4>2. Responsible Travel</h4>
                            <ul>
                              <li>a. We promote sustainable travel practices by offering eco-friendly travel options and advising clients on environmentally responsible choices.</li>
                              <li>b. We collaborate with local and global partners to support and develop sustainable tourism initiatives, such as eco-tourism, wildlife conservation, and cultural heritage preservation.</li>
                            </ul>

                            <h4>3. Employee Engagement</h4>
                            <ul>
                              <li>a. We encourage employees to adopt environmentally responsible behaviors in the workplace and provide training and resources to support their efforts.</li>
                              <li>b. We foster a culture of environmental awareness and accountability through ongoing communication and recognition of employees' contributions to sustainability.</li>
                            </ul>

                            <h4>4. Stakeholder Collaboration</h4>
                            <ul>
                              <li>a. We engage with stakeholders, including clients, suppliers, and local communities, to exchange ideas, share best practices, and develop strategies for environmental stewardship.</li>
                              <li>b. We actively participate in industry associations, conferences, and initiatives to contribute to the development of sustainable travel and tourism practices.</li>
                            </ul>

                            <h5>IV. Conclusion</h5>
                            <p>At Rytasoft, we recognize the importance of privacy and environmental sustainability in our business operations. We are committed to upholding the highest standards of data protection while contributing to the preservation of our planet for future generations.</p>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <div className="media-body">
                        Rytasoft Unit #154 7/8-14 Melverton Drive, Hallam, VIC 3803, Australia
                        Email: info@uptovisit.com
                        Phone: +61406766694
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </Col>
        </Row>
        {/* <!-- End Row --> */}
      </div>
    </>
  );
};

export default TermsAndConditions;