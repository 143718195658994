import { Helmet } from 'react-helmet-async';
import React from 'react';

export interface SeoProps {
    title: string;
    description: string;
    pathSlug: string;
    keywords: string;
}


const Seo :React.FC<SeoProps> = ({ title, description, pathSlug, keywords }) => {
	return (
<Helmet htmlAttributes={{ lang: 'en' }} title={title} meta={[
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
		]}
    />
 );
}
export default Seo;