import { useState, useEffect } from 'react';

const useViewportSize = () => {
  // Initial state depends on whether window is defined
  const [size, setSize] = useState(() => typeof window !== 'undefined'
    ? { width: window.innerWidth, height: window.innerHeight }
    : { width: 800, height: 600 }  // Default size for SSR
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setSize({ width: window.innerWidth, height: window.innerHeight });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return size;
};

export default useViewportSize;