import React from 'react';
import { FacebookShareButton, FacebookIcon } from 'react-share';

interface FacebookShareProps {
    url: string;
    quote?: string;
    hashtag?: string;
}

const FBShare: React.FC<FacebookShareProps> = ({ url, quote, hashtag }) => {
    return (
        <FacebookShareButton
            url={url}
            quote={quote}
            hashtag={hashtag}
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#1877F2',
                color: 'white',
                padding: '10px 15px',
                borderRadius: '25px',
                cursor: 'pointer',
                textDecoration: 'none'
            }}
        >
            <FacebookIcon size={32} round={true} style={{ marginRight: '10px' }} />
            <span>Share your personality places on Facebook</span>
        </FacebookShareButton>
    );
}

export default FBShare;