import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form, FormGroup } from 'react-bootstrap';
import Continent from '../Pages/Continent/Continent';

type Attraction = {
    id: string;
    name: string;
};

export type Continent = {
    id: number;
    name: string;
};

type ButtonGroupProps = {
    attractions: Attraction[];
    continents : Continent[];
    onSelectionChange: (selectedIds: (string | null)[], selectedContinent: number) => void;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({ attractions, onSelectionChange, continents }) => {
    const [active, setActive] = useState(new Array(attractions.length).fill(false));
    const [selectedContinent, setSelectedContinent] = useState(-1);

    const handleClick = (index: number) => {
        const newActive = [...active];
        newActive[index] = !newActive[index];
        setActive(newActive);
    };

    const handleFindClick = () => {
        // Get the ids of the selected attractions
        const selectedIds = active.map((isActive, i) => isActive ? attractions[i].id : null).filter(id => id !== null);
        onSelectionChange(selectedIds,selectedContinent);
    };


    function handleContinentClick(selected: Continent): void {
        console.log("selected continent: ", selected);
        setSelectedContinent(selected.id);
    }

    return (
        <>
                <Row>
            {continents.map((continent, index) => (
                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={12} key={continent.id}>

                <FormGroup className="form-group">
                  <Form.Label className="custom-switch ps-0">
                    <span className="custom-switch-description  tx-17 me-2">
                    {continent.name}
                    </span>
                    <Form.Control
                      type="radio"
                      name="custom-switch-checkbox3"
                      className="custom-switch-input"
                      checked={selectedContinent === continent.id}
                      onChange={() => handleContinentClick(continent)}
                    />
                    <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span>
                  </Form.Label>
                </FormGroup>
                </Col>
            ))}

        </Row>
        <hr />
        <Row>
            {attractions.map((attraction, index) => (
                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={12} key={attraction.id}>

                <FormGroup className="form-group">
                  <Form.Label className="custom-switch ps-0">
                    <span className="custom-switch-description  tx-17 me-2">
                    {attraction.name}
                    </span>
                    <Form.Control
                      type="checkbox"
                      name="custom-switch-checkbox3"
                      className="custom-switch-input"
                      onChange={() => handleClick(index)}
                    />
                    <span className="custom-switch-indicator custom-switch-indicator-lg custom-radius"></span>
                  </Form.Label>
                </FormGroup>
                </Col>
            ))}

        </Row>
        <Row>
                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={12} className='mg-l-auto'>
                    <Button onClick={() => handleFindClick()}
                    style={{ width: '100%', marginBottom: '1rem'}}>
                    Find
                    </Button>
                </Col>
        </Row>
        </>
    );
};

export default ButtonGroup;