import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Masonry from 'react-masonry-css';
import { Client, CountryDto, FindPlaceDto, FoundDto, FoundType, PlaceDetailDto, PlaceDto, ThingsToDoDto } from '../../../clients/clientApi';
import PlaceThumbnail from '../../PlaceThumbnail';
import { Link, useParams } from 'react-router-dom';
import LoadingOverlay from '../../LoadingOverlay';
import CountryThumbnail from '../../CountryThumbnail';
import FoundPlaceThumbnail from '../../FoundPlaceThumbnail';
import { Breadcrumb, Card, Col, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Image from '../../Elements/Image';
import GoogleMapComponent, { GoogleMapComponentProps } from '../../Elements/GoogleMapComponent';
import StarRating from '../../Elements/StarRating';
import Seo from '../../Elements/Seo';

const Place: FC = () => {
    const [place, setPlace] = useState<PlaceDto>({});
    const apiClient = new Client(process.env.REACT_APP_API_URL);
    const [isLoading, setIsLoading] = useState(false);
    const [coordinates, setCoordinates] = useState<GoogleMapComponentProps>({ center: { lat: 0, lng: 0 } });
    const [details, setDetails] = useState<PlaceDetailDto>({});
    const [htmlContent, setHtmlContent] = useState({__html:""});

    const { placeId } = useParams();
    const attractions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    let parseCoordinate = (coordinate: string): number => {
        const regex = /(-?\d+(\.\d+)?)[\s]*°[\s]*(N|S|E|W)?/i;
        const match = coordinate.match(regex);

        if (match) {
            let value = parseFloat(match[1]);
            const direction = match[3];

            if (direction) {
                if (direction.toUpperCase() === "S" || direction.toUpperCase() === "W") {
                    value = -value;
                }
            }

            return value;
        }

        return parseFloat(coordinate);
    }

    let thingsToDoSorted: ThingsToDoDto[] = [];
    if (place.thingsToDos) {
        thingsToDoSorted = place.thingsToDos?.sort((a, b) => {
            if (a.description === undefined && b.description === undefined) {
                return 0;
            } else if (a.description === undefined) {
                return 1;
            } else if (b.description === undefined) {
                return -1;
            } else {
                return a.description.localeCompare(b.description);
            }
        });
    }

    const breakpointColumnsObj = {
        default: 3,
        2000: 2,
        992: 2,
        768: 1,
        576: 1
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can change this to 'auto' for instant scrolling
        });
    }, []);


    useEffect(() => {
        async function fetchPlaces(placeId: string) {
            setIsLoading(true);
            const result = await apiClient.getPlace(placeId);
            setPlace(result);
            setCoordinates({
                center: {
                    lat: parseCoordinate(result.latitude ?? "0"),
                    lng: parseCoordinate(result.longitude ?? "0")
                }
            });

            setDetails(result.placeDetails?.[0] ?? {});
            setIsLoading(false);
        }

        fetchPlaces(placeId ?? '');

        fetch(`../articles/places/${placeId}.html`)
        .then((response) => response.text())
        .then((html) => {
            console.log(html);
          setHtmlContent({ __html: html });
        })
        .catch((error) => {
          console.warn(error);
        });

    }, [placeId]);

    const imagePath = process.env.REACT_APP_STORAGE_ADDRESS + `/places/${placeId}.jpg`;
    const defaultImageSource = process.env.REACT_APP_STORAGE_ADDRESS + `/common/defaultPlace.jpg`;

    return (
        <>
            <Seo description={details.shortDescription??''} keywords={details.tags ?? ""} title={`Up to visit - ${place.name}`} pathSlug={`place/${place.id}`} />

            {isLoading && <LoadingOverlay />}

            <div dangerouslySetInnerHTML={htmlContent} />

            <div>
                <div className="breadcrumb-header justify-content-between p-3">
                    <div className="left-content">
                        <h1 className="main-content-title mg-b-0 mg-b-lg-1">{place.name}</h1>
                    </div>
                    <div className="justify-content-center mt-2">
                        <Breadcrumb className="breadcrumb">
                            <Breadcrumb.Item className="breadcrumb-item tx-15" href={`/country/${place.countryId}`}>
                                Country ({place.countryName})
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                className="breadcrumb-item "
                                active
                                aria-current="page"
                            >
                                Place Details
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>

                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    <div className="place-item">
                        <Card className=" text-center card-img-top-1">
                            <Image src={imagePath} defaultSrc={defaultImageSource} className="radius-5" alt={`${place.name} Image`} />
                            <Card.Body>
                                <a className="btn btn-primary btn-block" href={place.website ?? ""} target='_blank'>
                                    More Details
                                </a>
                                <a className="btn btn-secondary btn-block" href={`http://www.google.com/search?q=${place.name}+${place.countryName}+visit`} target='_blank'>
                                    More on Google
                                </a>
                                <br />
                                <div className="media">{Array.isArray(place?.placeLinks) && place.placeLinks.length > 0 && <label className="label label-primary">Uptovisit links</label>}
                                    <div className="media-body">
                                        <Nav className="contact-info">
                                            {place.placeLinks?.map((item) => {
                                                let platformName: string;
                                                let platformIcon: string;
                                                switch (item.linkType) {
                                                    case 0:
                                                        platformName = 'Facebook';
                                                        platformIcon = 'fa-brands fa-facebook fa-2xl';
                                                        break;
                                                    case 1:
                                                        platformName = 'Instagram';
                                                        platformIcon = 'fab fa-instagram fa-2xl';
                                                        break;
                                                    case 2:
                                                        platformName = 'Website';
                                                        platformIcon = 'fab fa-thin fa-browser fa-2xl';
                                                        break;
                                                    case 3:
                                                        platformName = 'Youtube';
                                                        platformIcon = 'fab fa-youtube fa-2xl';
                                                        break;
                                                    case 4:
                                                        platformName = 'Tiktok';
                                                        platformIcon = 'fab fa-tiktok fa-2xl';
                                                        break;
                                                    case 5:
                                                        platformName = 'Twitter';
                                                        platformIcon = 'fab fa-twitter fa-2xl';
                                                        break;
                                                    case 6:
                                                        platformName = 'Vimeo';
                                                        platformIcon = 'fab fa-vimeo fa-2xl';
                                                        break;
                                                    default:
                                                        platformName = "Unknown"
                                                        platformIcon = '';
                                                }

                                                return (
                                                    <OverlayTrigger
                                                        key={item.id}
                                                        placement="top"
                                                        overlay={<Tooltip>{platformName}</Tooltip>}
                                                    >
                                                        <a className="contact-icon tx-inverse m-2" href={item.url} target='_blank'><span><i className={platformIcon}></i></span></a>
                                                    </OverlayTrigger>
                                                );
                                            })}
                                        </Nav>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>
                    </div>

                    <div className="place-item">
                        <Card className="card-img-top-1">
                            <Card.Body>
                                <div className="main-contact-info-body p-4">
                                    <div>
                                        <h2 className="mb-3">{place.name}</h2>
                                        <p className='fs-6'>
                                            {place.placeDetails?.[0].fullDescription}
                                        </p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    {thingsToDoSorted.length > 0 &&
                        <div className="place-item">
                            <Card className="card-img-top-1">
                                <Card.Body>
                                    <div className="main-contact-info-body p-4">
                                        <div className='fs-6'>
                                            <div>
                                                <h2 className="mb-3">Things to do</h2>
                                                {thingsToDoSorted.map((item, index) => (
                                                    <Card className="card-img-top-1" key={item.id}>
                                                        <Card.Body>
                                                            <div className="media-list pb-0">
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <div>
                                                                            <span className="fs-6">
                                                                                {item.description}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                ))}

                                            </div>

                                        </div>

                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    }
                    <div className="place-item">
                        <Card className="card-img-top-1">
                            <Card.Body>
                                <div className="main-contact-info-body p-4">
                                    <div className='fs-6'>
                                        <h2 className="mb-3">Attractions</h2>
                                        <div className='fs-6'>
                                            <Row>
                                                {attractions.map((item) => (
                                                    <Col key={item} sm={12} md={12} lg={12} xl={12} xxl={6} className="left-content">
                                                        <Card className="card-img-top-1">
                                                            <Card.Body>
                                                                <span >{place.ratings?.[item].attraction}</span>
                                                                <StarRating maxStars={10} readOnly rating={place.ratings?.[item].ratingValue ?? 0} />
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>))}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="place-item">
                        <Card className="card-img-top-1">
                            <Card.Body>
                                <div className="main-contact-info-body p-4">
                                    <div>
                                        <h2 className="mb-3">Location</h2>
                                    </div>
                                    <GoogleMapComponent center={coordinates.center} />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Masonry>
            </div>
        </>
    );
};

export default Place;