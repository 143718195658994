import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders"
import BasicSearch from './components/Pages/Search/BasicSearch';
import Country from './components/Pages/Country/Country';
import Place from './components/Pages/Place/place';
import Continent from './components/Pages/Continent/Continent';
import Aboutus from './components/Pages/Aboutus/Aboutus';
import Contactus from './components/Pages/Contactus/Contactus';
import TermsAndConditions from './components/Pages/TermsAndConditions';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import ActivitySearch from './components/Pages/Search/ActivitySearch';
import UnderConstruction from './components/Pages/UnderConstruction/UnderConstruction';
import PersonalitySearch from './components/Pages/Search/PersonalitySearch';
import PersonalitySearchResult from './components/Pages/Search/PersonalitySearchResult';


const App = React.lazy(() => import("./App"));
const Home = React.lazy(() =>
  import("./components/Pages/Home/Home")
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path={`/`} element={<App />}>
            <Route
              path={`/`}
              element={<Home />}
            />
            <Route
              path={`/search/:searchTerm`}
              element={<BasicSearch />}
            />
            <Route
              path={`/continents/:continentNumber`}
              element={<Continent />}
            />
            <Route
              path={`/country/:countryId`}
              element={<Country />}
            />
            <Route
              path={`/place/:placeId`}
              element={<Place />}
            />
            <Route
              path={`/pages/aboutus`}
              element={<Aboutus />}
            />
            <Route
              path={`/pages/contactus`}
              element={<Contactus />}
            />
            <Route
              path={`/pages/activitySearch`}
              element={<ActivitySearch />}
            />
            <Route
              path={`/pages/personalitySearch`}
              element={<PersonalitySearch />}
            />
            <Route
              path={`/pages/personalitySearchResult/:personalityKey`}
              element={<PersonalitySearchResult />}
            />
            <Route
              path={`/pages/comingSoon`}
              element={<UnderConstruction />}
            />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
