import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Masonry from 'react-masonry-css';
import { Client, FindPlaceDto, FoundDto, WorldContinent } from '../../../clients/clientApi';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../LoadingOverlay';
import FoundPlaceThumbnail from '../../FoundPlaceThumbnail';
import ButtonGroup from '../../Elements/ButtonGroup';
import { Accordion, Breadcrumb, Card } from 'react-bootstrap';

const ActivitySearch: FC = () => {
  const [places, setPlaces] = useState<FoundDto[]>([]);
  const apiClient = new Client(process.env.REACT_APP_API_URL);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("Item1");

  const { searchTerm } = useParams();

  const breakpointColumnsObj = {
    default: 6,
    1200: 4,
    992: 3,
    768: 2,
    576: 1
  };

  async function fetchPlaces(searchTerm: string, selectedIds: string[],selectedContinent:number) {
    setIsLoading(true);
    let findPlace: FindPlaceDto = {
      continent: selectedContinent as WorldContinent,
      desiredAttractions: selectedIds.map(x => ({ attractionId: x, level: -1 })),
      filterContinent: selectedContinent !== -1,
      rowNumbers: 24,
      searchTerm: searchTerm
    }
    console.log("selectedContinent", selectedContinent);
    const result = await apiClient.find(findPlace);
    setActiveKey("Item2");
    setPlaces(result);
    setIsLoading(false);
  }

  const handleSelectionChange = (selectedIds: (string | null)[], selectedContinent: number) => {
    fetchPlaces(searchTerm ?? '', selectedIds.filter(x => x !== null) as string[], selectedContinent);
  };

  const attractions = [
    { id: '7CD76553-AC37-412D-813B-A2B759EF0E38', name: 'CulturalAttractions' },
    { id: '1C3BA93A-7262-48A3-9F79-232331576411', name: 'ThemeParks' },
    { id: 'B4F586DF-CA01-49E4-9AB8-C1B40E9148FD', name: 'BeachDestinations' },
    { id: 'A01674B0-A19D-491F-8236-DB6218023D59', name: 'HistoricalAttractions' },
    { id: 'B963538F-3843-4C0C-8BEC-001338CCBA4C', name: 'AdventureDestinations' },
    { id: 'FC7437F2-52E7-4A4B-B691-252BABABB48B', name: 'FunAndClubbing' },
    { id: '3389E02A-B324-4F2C-A660-28A888D4681D', name: 'KidsFriendly' },
    { id: '8F489B36-1B09-4DB3-9349-FBBEE7FBB41A', name: 'NaturalAttractions' },
    { id: '032B9120-4B25-4FEC-BDC9-CC37D29A7783', name: 'Wildlife' },
    { id: '33EC556D-16F4-4CDA-B504-1E699F6D8E3A', name: 'ModernAttractions' },
    { id: '1107F7D8-5C92-4FEA-8FD4-89680606EBD3', name: 'AdultActivities' }
  ];

  const continents = [
    { id:-1, name:'All Continents'},
    { id:0, name:'Africa'},
    { id:2, name:'Asia'},
    { id:3, name:'Europe'},
    { id:4, name:'North America'},
    { id:5, name:'South America'},
    { id:6, name:'Oceania'},
  ];

  return (
    <>
      <div>
        <Helmet>
          <title>Up to visit - Attraction Search</title>
        </Helmet>
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">Search By Attraction</span>
          </div>
          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item className="breadcrumb-item tx-15" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                Attraction Search
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        {isLoading && <LoadingOverlay />}

        <Accordion activeKey={activeKey} id="accordion">
          <Accordion.Item eventKey="Item1" onClick={() => setActiveKey("Item1")}>
            <Accordion.Header
              className=" "
              id="headingOne"
            >
              Attractions
            </Accordion.Header>
            <Accordion.Body className="borders">
              <Card className=" bg-primary-transparent">
                <Card.Body>
                  <ButtonGroup attractions={attractions} onSelectionChange={handleSelectionChange} continents={continents} />
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="Item2" onClick={() => setActiveKey("Item2")}>
            <Accordion.Header
              className=" "
              id="headingTwo"
            >
              Places
            </Accordion.Header>
            <Accordion.Body className="borders">
              <Card className=" bg-primary-transparent">
                <Card.Body>
                  <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                    {places.map((place: FoundDto) => (
                      <FoundPlaceThumbnail key={place.id} data={place} />
                    ))}
                  </Masonry>
                </Card.Body>
              </Card>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>



      </div>
    </>
  );
};

export default ActivitySearch;