import React from 'react';
import { Breadcrumb, Card, Col, Nav, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import SubscriptionForm from '../../Elements/SubscriptionForm';
import { Link } from 'react-router-dom';
import companyInfo from '../../../companyInfo';



const Contactus = () => (
  <div>
    {/* <!-- breadcrumb --> */}
    <div className="breadcrumb-header justify-content-between">
      <div className="left-content">
        <span className="main-content-title mg-b-0 mg-b-lg-1">CONTACT US</span>
      </div>
      <div className="justify-content-center mt-2">
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item className="breadcrumb-item tx-15" href="/">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className="breadcrumb-item "
            active
            aria-current="page"
          >
            About Us
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </div>
    {/* <!-- /breadcrumb --> */}
    {/* <!-- row ---> */}
    <div className="container">
      <Row className="about-main mb-5">
        <Col lg={8} md={8} sm={12} className="text-center">
          <h1 className="mb-3 font-weight-semibold tx-46">
            Contact Us
          </h1>
          <p className="leading-normal lead-1">
            Thanks for visiting out website
          </p>
        </Col>
      </Row>
      <Row className="br-5">
        <p>
          <img
            src={require("../../../assets/img/contactus.jpg")}
            className="br-5"
            alt=""
          />
        </p>
      </Row>
      <Row className="py-5 about-motto pt-0">
        <Col lg={8} md={8} sm={12} >
          <Card>
            <Card.Header className="pb-0">
              <div className="text-dark tx-26 font-weight-semibold">
                Subscription
              </div>
            </Card.Header>
            <Card.Body>
              <SubscriptionForm />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header className="pb-0">
              <div className="text-dark tx-26 font-weight-semibold">
                Contact Details
              </div>
            </Card.Header>
            <Card.Body>
              <div className="">
                <div className="main-content-body main-content-body-contacts card custom-card">
                  <div className="main-contact-info-header pt-3">
                    <div className="media">
                      <div className="media-body">
                        <h5>www.uptovisit.com</h5>
                        <Nav className="contact-info">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Facebook</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-2" href={companyInfo.facebook} target='_blank'><span><i className="fa-brands fa-facebook fa-2xl"></i></span></a>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Twitter</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-2" href={companyInfo.twitter} target='_blank'><span><i className="fab fa-twitter fa-2xl"></i></span></a>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Instagram</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-2" href={companyInfo.instagram} target='_blank'><span><i className="fab fa-instagram fa-2xl"></i></span></a>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Tiktok</Tooltip>}
                          >
                            <a className="contact-icon tx-inverse m-2" href={companyInfo.tiktok}  target='_blank'><span><i className="fab fa-tiktok fa-2xl"></i></span></a>
                          </OverlayTrigger>
                        </Nav>
                      </div>
                    </div>
                  </div>
                  <div className="main-contact-info-body p-4">
                    <div className="media-list pb-0">
                      <div className="media">
                        <div className="media-body">
                          <div>
                            <label>Phone</label>{" "}
                            <span className="tx-medium">+61406766694</span>
                          </div>
                          <div>
                            <label>Email</label>{" "}
                            <span className="tx-medium">info@uptovisit.com</span>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-body">
                          <div>
                            <label>Gmail Account</label>{" "}
                            <span className="tx-medium">uptovisit@gmail.com</span>
                          </div>
                          <div>
                            <label>WhatsApp</label>{" "}
                            <span className="tx-medium">+61406766694</span>
                          </div>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-body">
                          <div>
                            <label>Current Address :</label>{" "}
                            <span className="tx-medium">
                              Rytasoft Unit #154 7/8-14 Melverton Drive, Hallam, VIC 3803, Australia
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>

    {/* <!-- /row ---> */}
  </div>
);

Contactus.propTypes = {};

Contactus.defaultProps = {};

export default Contactus;
