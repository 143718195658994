import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Masonry from 'react-masonry-css';
import { Client, FindPlacePersonalityDto, PlaceDto } from '../../../clients/clientApi';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../LoadingOverlay';
import FoundPlaceThumbnail from '../../FoundPlaceThumbnail';
import { Breadcrumb } from 'react-bootstrap';
import FBShare from '../../Elements/Social/FBShare';


const PersonalitySearchResult: FC = () => {
  const [places, setPlaces] = useState<PlaceDto[]>([]);
  const apiClient = new Client(process.env.REACT_APP_API_URL);
  const [isLoading, setIsLoading] = useState(false);

  const { personalityKey } = useParams();

  console.log(`PersonalitySearchResult: ${personalityKey}`);
  const currentUrl = window.location.href;

  const breakpointColumnsObj = {
    default: 6,
    1200: 4,
    992: 3,
    768: 2,
    576: 1
  };

  useEffect(() => {
    async function fetchPlaces() {
      setIsLoading(true);

      let findPlace: FindPlacePersonalityDto = {
        personality: personalityKey,
        rowNumbers: 12
      };

      const result = await apiClient.findPlacesByPersonality(true, findPlace);
      console.log("result", result);
      setPlaces(result);
      setIsLoading(false);
    }

    if (personalityKey) {
      fetchPlaces();
    }

  }, [personalityKey]);

  const imagePath = process.env.REACT_APP_STORAGE_ADDRESS + `/personalities/${personalityKey}.jpg`;

  return (
    <>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Places based on your personality</span>
        </div>
        <div className="justify-content-center mt-2">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item className="breadcrumb-item tx-15" href="/">
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumb-item "
              active
              aria-current="page"
            >
              Personality Places
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Helmet>
        <title>Up to visit</title>
        <meta name="description" content="Embark on a journey of discovery with our collection of destinations across the world's continents. From the bustling cities of Europe to the wilds of Africa and the stunning natural landscapes of Asia, our site provides inspiration and practical advice for travelers seeking unforgettable experiences. Start planning your next adventure today with our expertly curated guides to destinations on every continent" />
        <meta name="keywords" content="Europe travel destinations, Asia travel destinations, Africa travel destinations, North America travel destinations, South America travel destinations, Australia travel destinations, Adventure travel, City guides, Beach vacations, Cultural experiences, Nature tourism, Scenic views, Travel tips" />
      </Helmet>
      <div className="d-flex justify-content-center">
        <img style={{
          display: 'flex',
          color: 'white',
          padding: '10px 15px',
          borderRadius: '40px',
        }} src={imagePath} alt="personality" className="img-fluid" width={400} height={400} />
      </div>
      <div className="d-flex justify-content-center">
        <FBShare url={currentUrl} quote="Check out this awesome content!" />
      </div>
      {isLoading && <LoadingOverlay />}
      {(places && places.length > 0) && <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {places.map((item) => (
          <div key={item.id} className="place-item">
            {<FoundPlaceThumbnail data={item} />}
          </div>
        ))}
      </Masonry>}
      {(!places || places.length === 0) &&
        <div role="alert" className="fade alert mb-2 alert-warning alert show mg-t-80">
          <div className="alert-heading h4"><h4 className="alert-heading">
            <i className="fe fe-alert-octagon me-2 tx-20"></i>No places found!</h4>
          </div>
          <p>Please use another keywords.</p>
        </div>
      }
    </>
  );
};

export default PersonalitySearchResult;