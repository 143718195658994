import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Masonry from 'react-masonry-css';
import { Client, CountryDto, FindPlaceDto, FoundDto, FoundType, PlaceDto } from '../../../clients/clientApi';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../LoadingOverlay';
import FoundPlaceThumbnail from '../../FoundPlaceThumbnail';
import { Breadcrumb } from 'react-bootstrap';
import Seo from '../../Elements/Seo';

const Country: FC = () => {
  const [country, setCountry] = useState<CountryDto>({});
  const apiClient = new Client(process.env.REACT_APP_API_URL);
  const [isLoading, setIsLoading] = useState(false);

  const { countryId } = useParams();

  const breakpointColumnsObj = {
    default: 6,
    1500: 4,
    1200: 3,
    992: 2,
    768: 1,
    576: 1
  };

  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can change this to 'auto' for instant scrolling
      });
}, []);


  useEffect(() => {
    async function fetchPlaces(countryId: string) {
      setIsLoading(true);
      const result = await apiClient.getCountryDetails(countryId, true);

      setCountry(result);
      setIsLoading(false);
    }

    fetchPlaces(countryId??'');
  }, [countryId]);

  return (
    <>
      <Seo description={country.countryDetails?.[0].shortDescription??''} keywords={country.countryDetails?.[0].tags ?? ""} title={`Up to visit - ${country.name}`} pathSlug={`country/${country.id}`} />

      {isLoading && <LoadingOverlay />}
      <div>
                <div className="breadcrumb-header justify-content-between">
                    <div className="left-content">
                        <span className="main-content-title mg-b-0 mg-b-lg-1">{country.name}</span>
                    </div>

                    <div className="justify-content-center mt-2">
                        <Breadcrumb className="breadcrumb">
                            <Breadcrumb.Item className="breadcrumb-item tx-15" href={`/continents/${country.continentNumber}`}>
                                Continent ({country.continentName})
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                className="breadcrumb-item "
                                active
                                aria-current="page"
                            >
                                Country Places
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                </div>
                {(country.places && country.places.length > 0) && <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {country.places.map((item) => (
          <div key={item.id} className="place-item">
             <FoundPlaceThumbnail data={item} />       
          </div>
        ))}
      </Masonry>}
      {(!country.places || country.places.length == 0) && <div className="text-center">No places found</div>}
    </div>
    </>
  );
};

export default Country;