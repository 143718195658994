import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FoundDto } from '../clients/clientApi';
import { Card } from 'react-bootstrap';
import  Image from './Elements/Image';


export interface PlaceObject {
  data: FoundDto,
}

const FoundPlaceThumbnail: FC<PlaceObject> = (props) => {

  const { data } = props;
  const defaultImageSource = process.env.REACT_APP_STORAGE_ADDRESS + `/common/defaultPlace.jpg`;
  const imagePath = process.env.REACT_APP_STORAGE_ADDRESS + `/places/${data.id}.jpg`;
  return (

    <Link to={`/place/${data.id}`}>
      <Card className=" text-center card-img-top-1 p-1">
        <Image src={imagePath} defaultSrc={defaultImageSource} alt={data.name} className='card-img-top w-100' />
        <Card.Body>
          <h4 className="card-title mb-3">{data.name}</h4>
          <p className="card-text">
             {data.parentName}
          </p>
          <div className="btn btn-primary btn-block">
            Learn More
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default FoundPlaceThumbnail;