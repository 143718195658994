import React from 'react';
import './StarRating.css';

interface StarRatingProps {
  maxStars: number;
  rating: number;
  readOnly?: boolean;
}

const StarRating: React.FC<StarRatingProps> = ({ maxStars, rating, readOnly }) => {
  const handleMouseEvents = (e: React.MouseEvent) => {
    if (readOnly) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className="star-rating" onMouseOver={handleMouseEvents} onMouseOut={handleMouseEvents}>
      {Array.from({ length: maxStars }, (_, i) => i + 1).map((star) => (
        <i
          key={star}
          className={`star fa${rating >= star ? 's' : 'r'} fa-star`}
          onClick={handleMouseEvents}
        ></i>
      ))}
    </div>
  );
};

export default StarRating;