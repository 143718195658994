import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { FoundDto } from '../clients/clientApi';
import  Image from './Elements/Image';


export interface CountryObject {
  data: FoundDto,
}

const CountryThumbnail: FC<CountryObject> = (props) => {

  const { data } = props;

  const imagePath = process.env.REACT_APP_STORAGE_ADDRESS + `/countries/${data.id}.jpg`;
  const flagPath = process.env.REACT_APP_STORAGE_ADDRESS + `/flags/${data.countryCode}.png`;
  const defaultImageSource = process.env.REACT_APP_STORAGE_ADDRESS + `/common/defaultPlace.jpg`;

  return (

    <Link to={`/country/${data.id}`}>
      <Card className=" text-center card-img-top-1">
      <Image src={imagePath} defaultSrc={defaultImageSource} alt={data.name} className='card-img-top w-100' />
        <Card.Body>
          <h4 className="card-title mb-3">{data.name}</h4>
          <img src={flagPath} alt={data.name} className='card-img-top w-50 flag-image'  />
          <div className="btn btn-primary btn-block">
            Explore Country
          </div>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default CountryThumbnail;