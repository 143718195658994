import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Client, SubscriptionDto } from '../../clients/clientApi';
import { Form, FormGroup } from 'react-bootstrap';


// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 15px;
// `;

const Input = styled.input`
  padding: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 5px 10px;
  font-size: 1rem;
`;

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const PopupContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
`;

interface FormData {
  name: string;
  email: string;
}

const SubscriptionForm: FC = () => {
  const [formData, setFormData] = useState<FormData>({ name: '', email: '' });
  const [showPopup, setShowPopup] = useState(false);
  const [hasError, sethasError] = useState(false);
  const apiClient = new Client(process.env.REACT_APP_API_URL);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    let subscritionData: SubscriptionDto = {
      name: formData.name,
      email: formData.email
    };

    try {
      await apiClient.subscribe(subscritionData);
      sethasError(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      sethasError(true);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }

    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  return (
    <>
      {showPopup && (
        <Popup>
          {hasError && <PopupContent>Sorry there was a problem! You may try again!</PopupContent>}
          {!hasError && <PopupContent>Thanks for subscribing!</PopupContent>}
        </Popup>
      )}

      <Form className="form-horizontal" onSubmit={handleSubmit}>
        <FormGroup className="form-group">
          <Form.Control
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup className="form-group">
          <Form.Control
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup className="form-group mb-0 mt-3 justify-content-end">
          <div>
            <Button type="submit" className="btn btn-primary">
              Subscribe
            </Button>
          </div>
        </FormGroup>
      </Form>
    </>
  );
};

export default SubscriptionForm;