import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useViewportSize from '../../../hooks/useViewportSize';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Breadcrumb, Button } from 'react-bootstrap';
import MBTITest, { Trait } from '../../Elements/MBTITest/MBTITest';
import { on } from 'events';

export interface LocationState {
  selectedPersonality: string;
}

const traits: Trait[] = ['Thinking', 'Judging', 'Introverted', 'Sensing', 'Feeling', 'Perceiving', 'Extraverted', 'Intuitive'];

const preferences: Record<Trait, string[]> = {
  'Introverted': [
    'You prefer to recharge through alone time, rather than being energized by a crowd',
    'You prefer having fewer, but deeper relationships, rather than many surface-level friendships',
  ],
  'Sensing': [
    'You prefer dealing with tangible facts and details, rather than conceptual ideas and possibilities',
    'You prefer focusing your attention on the present and what is currently happening, rather than on the future and potential outcomes',
  ],
  'Thinking': [
    'You prefer to make decisions based on logic and consistency, rather than considering people and special circumstances first',
    'You would rather be just, even if it means being less compassionate',
  ],
  'Judging': [
    'You prefer to have things decided and planned, rather than being open to new information and options',
    'You prefer to work towards finishing a project, rather than focusing on the process of improving and learning as you go along',
  ],
  'Feeling': [
    'You prefer to consider people and their unique circumstances when making decisions, rather than strictly following logic',
    'You would rather be compassionate, even if it means being less just',
  ],
  'Perceiving': [
    'You prefer to stay open to new information and options, rather than having everything decided and planned',
    'You prefer the process of improving and learning as you go along in a project, rather than simply finishing the task',
  ],
  'Extraverted': [
    'You prefer to draw energy from a crowd and being around others',
    'You prefer to maintain numerous friendships with more breadth than depth',
  ],
  'Intuitive': [
    'You prefer dealing with abstract concepts and ideas, rather than concrete facts and details',
    'You prefer thinking about the future and exploring possibilities, rather than focusing on the present moment',
  ],
};


export interface Personality {
  key: string;
  name: string;
}

type PersonalityType = {
  [key: string]: string
}

const personalityMap: PersonalityType = {
  'INTJ': 'The Mastermind',
  'INTP': 'The Architect',
  'ENTJ': 'The Commander',
  'ENTP': 'The Visionary',
  'INFJ': 'The Counselor',
  'INFP': 'The Healer',
  'ENFJ': 'The Teacher',
  'ENFP': 'The Champion',
  'ISTJ': 'The Inspector',
  'ISFJ': 'The Protector',
  'ESTJ': 'The Supervisor',
  'ESFJ': 'The Provider',
  'ISTP': 'The Craftsman',
  'ISFP': 'The Composer',
  'ESTP': 'The Entrepreneur',
  'ESFP': 'The Performer'
};

const getAbbreviation = (traits: Trait[]): string => {
  let abbreviations: { [key: string]: string } = {
    'Introverted': 'I',
    'Extraverted': 'E',
    'Sensing': 'S',
    'Intuitive': 'N',
    'Thinking': 'T',
    'Feeling': 'F',
    'Judging': 'J',
    'Perceiving': 'P',
  };
  return traits.map(trait => abbreviations[trait]).join('');
};

const getPersonality = (traits: Trait[]): Personality => {
  const abbreviations = getAbbreviation(traits);
  const abbreviationsSet = new Set(abbreviations);

  for (const key in personalityMap) {
    const keySet = new Set(key.split(''));
    if (
      new Set(
        Array.from(abbreviationsSet).concat(Array.from(keySet))
      ).size === abbreviationsSet.size
    ) {
      return {
        key: key,
        name: personalityMap[key]
      };
    }
  }

  return {
    key: 'Unknown',
    name: 'Unknown Personality'
  };;
};

interface ResultPanelProps {
  selectedTraits: Trait[];
  footerOffset: number;
}

const ResultPanel = styled.div<ResultPanelProps>`
  position: fixed; 
  bottom: 0; 
  right: 0;
  width: 100%; 
  height: auto; 
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  transform: ${props => props.selectedTraits.length > 0 ? 'translateY(0)' : 'translateY(100%)'};
  
  @media (min-width: 768px) { 
    display: flex;
    position: absolute; 
    top: 0px; 
    bottom: 80px;
    width: 300px; 
    height: auto;
    overflow-y:scroll;
    padding-top:150px;
    z-index:999;
    transform: ${props => props.selectedTraits.length > 0 ? 'translateX(0)' : 'translateX(100%)'};
  }

  @media (max-width: 767px) { 
    top: ${props => props.footerOffset - 200}px;
    max-height: 200px;;
    overflow-y:scroll;
    z-index:999;
  }

  @media (max-width: 400px) { 
    top: ${props => props.footerOffset - 100}px;
    max-height: 100px;
    overflow-y:scroll;
    z-index:999;
  }

  @media (width: 540px) { 
    top: ${props => props.footerOffset - 100}px;
    max-height: 100px;
    overflow-y:scroll;
    z-index:999;
  }
`;

interface ContainerProps {
  selectedTraits: Trait[];
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  overflow:hidden;
`;


// @media(min - width: 768px) {
//   height: 100vh;
//   margin - left: ${ props => props.selectedTraits.length > 0 ? '-300px' : '0' }
// }

const PieSlice = styled.path<{ selected: boolean, hovered: boolean }>`
  cursor: pointer;
  stroke: ${props => props.selected ? '#000' : '#fff'};
  stroke-width: 5px;
  transform: scale(${props => props.hovered ? 1.05 : 1});
  transition: transform 0.3s;
  transform-origin: 50% 50%;
  fill: ${props => props.selected ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.7)'};
`;


type SliceLabelProps = {
  selected: boolean;
  fontSize: number;
};

const SliceLabel = styled.text<SliceLabelProps>`
  fill: ${props => props.selected ? '#000' : '#fff'};
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.selected ? 'bold' : 'normal'};
  text-anchor: middle;
  dominant-baseline: middle;
  pointer-events: none;
`;

const CenterButton = styled.circle`
  cursor: pointer;
  fill: #0D6EFD;
`;

const CenterLabel = styled.text`
  fill: #fff;
  font-size: 16px;
  text-anchor: middle;
  dominant-baseline: middle;
  pointer-events: none;
`;

const PersonalityCircle: React.FC = () => {
  const [selectedTraits, setSelectedTraits] = useState<Trait[]>([]);
  const [hoveredTrait, setHoveredTrait] = useState<Trait | null>(null);
  const [footerOffset, setFooterOffset] = useState(0);
  const [personality, setPersonality] = useState<Personality>();

  useEffect(() => {
    const footer = document.querySelector('.main-footer');
    const footerRect = footer?.getBoundingClientRect();  // Get the position of the footer

    console.log(footerRect?.top);
    setFooterOffset(footerRect?.top || 0);
  }, []);

  const handleSelect = (trait: Trait) => {
    const oppositeTraits: Record<Trait, Trait> = {
      'Introverted': 'Extraverted',
      'Extraverted': 'Introverted',
      'Sensing': 'Intuitive',
      'Intuitive': 'Sensing',
      'Thinking': 'Feeling',
      'Feeling': 'Thinking',
      'Judging': 'Perceiving',
      'Perceiving': 'Judging',
    };

    if (selectedTraits.includes(trait)) {
      setSelectedTraits(selectedTraits.filter(t => t !== trait));
    } else {
      // If the selectedTraits already includes the opposite trait, remove it
      const newTraits = selectedTraits.includes(oppositeTraits[trait])
        ? selectedTraits.filter(t => t !== oppositeTraits[trait])
        : [...selectedTraits];

      // Check if there are already 4 traits selected, and if so, remove the first one before adding the new one
      if (newTraits.length === 4) {
        newTraits.shift();
      }

      newTraits.push(trait);
      setSelectedTraits(newTraits);
    }
  };

  useEffect(() => {
    if (selectedTraits.length === 4) {
      const personality = getPersonality(selectedTraits);
      setPersonality(personality);
    }
  }, [selectedTraits]);

  const navigate = useNavigate();

  const handleSearch = () => {
    if (personality) {
      navigate(`../pages/personalitySearchResult/${personality.key}`);
    }
    else {
      toast('🦄 Please choose at least four', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const onTestSelected = (trait: Trait) => {
    handleSelect(trait);
  }

  const { width, height } = useViewportSize();
  const screenMin = Math.min(width, height);

  let fontSize = screenMin * 0.017;

  let pieSize = screenMin * 0.4;

  if (screenMin < 600) {
    if (height / width < 1.34) {
      pieSize = screenMin * 0.7;
      fontSize = screenMin * 0.03;
    }
    else {
      pieSize = screenMin * 0.9;
      fontSize = screenMin * 0.04;
    }
  }

  const pieRadius = pieSize / 2;
  const enlargedPieSize = pieSize * 1.06;
  const viewBoxOffset = (enlargedPieSize - pieSize) / 2;

  return (<>

    <div className="breadcrumb-header justify-content-between">
      <div className="left-content">
        <span className="main-content-title mg-b-0 mg-b-lg-1">Search By Personality</span>
      </div>
      <div className="justify-content-center mt-2">
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item className="breadcrumb-item tx-15" href="/">
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            className="breadcrumb-item "
            active
            aria-current="page"
          >
            Personality Search
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </div>
    <MBTITest onSelected={onTestSelected} onRestart={() => {
      setSelectedTraits([]);
      setPersonality(undefined);
    }} />

    <Container selectedTraits={selectedTraits}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

      <svg width={enlargedPieSize} height={enlargedPieSize} viewBox={`${-viewBoxOffset} ${-viewBoxOffset} ${enlargedPieSize} ${enlargedPieSize}`}>
        <defs>
          <pattern id="whole-chart-image" patternUnits="userSpaceOnUse" width="100%" height="100%">
            <image href="../../../../images/personality-circle.png" x="0" y="0" width={pieSize} height={pieSize} />
          </pattern>
        </defs>
        <circle cx={pieRadius} cy={pieRadius} r={pieRadius} fill="url(#whole-chart-image)" />
        {traits.map((trait, index) => {
          const startAngle = index * (360 / traits.length);
          const endAngle = (index + 1) * (360 / traits.length);
          const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

          const x1 = pieRadius + pieRadius * Math.cos(Math.PI * startAngle / 180);
          const y1 = pieRadius + pieRadius * Math.sin(Math.PI * startAngle / 180);

          const x2 = pieRadius + pieRadius * Math.cos(Math.PI * endAngle / 180);
          const y2 = pieRadius + pieRadius * Math.sin(Math.PI * endAngle / 180);

          const labelAngle = startAngle + (endAngle - startAngle) / 2;
          const labelX = pieRadius + (3 * pieRadius / 4) * Math.cos(Math.PI * labelAngle / 180);
          const labelY = pieRadius + (3 * pieRadius / 4) * Math.sin(Math.PI * labelAngle / 180);

          return (
            <g key={trait}>
              <PieSlice
                selected={selectedTraits.includes(trait)}
                hovered={hoveredTrait === trait}
                onClick={() => handleSelect(trait)}
                onMouseEnter={() => setHoveredTrait(trait)}
                onMouseLeave={() => setHoveredTrait(null)}
                d={`M${pieRadius},${pieRadius} L${x1},${y1} A${pieRadius},${pieRadius} 0 ${largeArcFlag},1 ${x2},${y2} z`}
              />
              <SliceLabel selected={selectedTraits.includes(trait)} fontSize={fontSize} x={labelX} y={labelY}>
                {trait}
              </SliceLabel>
            </g>
          );

        })}



        <CenterButton onClick={handleSearch} cx={pieRadius} cy={pieRadius} r={pieRadius / 3} />
        <CenterLabel x={pieRadius} y={pieRadius}>
          Search
        </CenterLabel>

      </svg>
      {/* {selectedTraits.length > 0 && <ResultPanel footerOffset={footerOffset} selectedTraits={selectedTraits}>
        <Button variant="" className="btn me-2 btn-lg btn-primary" onClick={handleSearch}>
          {selectedTraits.length < 4 ? 'Choose 4 traits please' : `You are ${personality?.name}`}
        </Button>
        <hr />
        <ul>
          {selectedTraits.map(trait => (
            <li key={trait}>
              <h3>{trait}</h3>
              <ul>
                {preferences[trait].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </ResultPanel>} */}

      {/* {selectedTraits.length === 4 && <h2>Your Personality Type: {selectedTraits.join(' - ')}</h2>} */}
    </Container>
  </>
  );
};

export default PersonalityCircle;

