import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Masonry from 'react-masonry-css';
import { Client, FindPlaceDto, FoundDto, FoundType, PlaceDto } from '../../../clients/clientApi';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../LoadingOverlay';
import CountryThumbnail from '../../CountryThumbnail';
import FoundPlaceThumbnail from '../../FoundPlaceThumbnail';
import { Alert } from 'react-bootstrap';

const BasicSearch: FC = () => {
  const [places, setPlaces] = useState<FoundDto[]>([]);
  const apiClient = new Client(process.env.REACT_APP_API_URL);
  const [isLoading, setIsLoading] = useState(false);

  const { searchTerm } = useParams();

  const breakpointColumnsObj = {
    default: 6,
    1200: 4,
    992: 3,
    768: 2,
    576: 1
  };

  useEffect(() => {
    async function fetchPlaces(searchTerm: string) {
      setIsLoading(true);
      let findPlace: FindPlaceDto = {
        desiredAttractions: [],
        filterContinent: false,
        rowNumbers: 24,
        searchTerm: searchTerm
      }
      console.log("findPlace", findPlace);
      const result = await apiClient.find(findPlace);
      setPlaces(result);
      setIsLoading(false);
    }

    fetchPlaces(searchTerm ?? '');
  }, [searchTerm]);

  return (
    <>
      <Helmet>
        <title>Up to visit</title>
        <meta name="description" content="Embark on a journey of discovery with our collection of destinations across the world's continents. From the bustling cities of Europe to the wilds of Africa and the stunning natural landscapes of Asia, our site provides inspiration and practical advice for travelers seeking unforgettable experiences. Start planning your next adventure today with our expertly curated guides to destinations on every continent" />
        <meta name="keywords" content="Europe travel destinations, Asia travel destinations, Africa travel destinations, North America travel destinations, South America travel destinations, Australia travel destinations, Adventure travel, City guides, Beach vacations, Cultural experiences, Nature tourism, Scenic views, Travel tips" />
      </Helmet>
      {isLoading && <LoadingOverlay />}
      {(places && places.length > 0) && <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {places.map((item) => (
          <div key={item.id} className="place-item">
            {(item.foundType === FoundType._1) && <FoundPlaceThumbnail data={item} />}
            {(item.foundType === FoundType._0) && <CountryThumbnail data={item} />}
          </div>
        ))}
      </Masonry>}
      {(!places || places.length == 0) &&


        // <div className="ht-50 text-center bg-secondary-gradient mg-t-100">No places found</div>

        <div role="alert" className="fade alert mb-2 alert-warning alert show mg-t-80">
          <div className="alert-heading h4"><h4 className="alert-heading">
            <i className="fe fe-alert-octagon me-2 tx-20"></i>No places found!</h4>
            </div>
            <p>Please use another keywords.</p>
          </div>
         }
    </>
  );
};

export default BasicSearch;