import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Masonry from 'react-masonry-css';
import { Client, CountryDto, FindPlaceDto, FoundDto, FoundType, PlaceDto, WorldContinent } from '../../../clients/clientApi';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../LoadingOverlay';
import FoundPlaceThumbnail from '../../FoundPlaceThumbnail';
import { Breadcrumb } from 'react-bootstrap';
import CountryThumbnail from '../../CountryThumbnail';

const Continent: FC = () => {
  const [countries, setCountries] = useState<FoundDto[]>([]);

  const apiClient = new Client(process.env.REACT_APP_API_URL);
  const [isLoading, setIsLoading] = useState(false);

  const { continentNumber } = useParams();

  const breakpointColumnsObj = {
    default: 6,
    1500: 4,
    1200: 3,
    992: 2,
    768: 1,
    576: 1
  };

  var continent = parseInt(continentNumber??'0') as WorldContinent ;

  let continentName = () => { switch(continent) 
  {
    case 0:
      return "Africa";
    case 1:
      return "Antarctica";
    case 2:
      return "Asia";
    case 3:
      return "Europe";
    case 4:
      return "North America";
      case 5:
        return "South America";
    case 6:
      return "Oceania";
    default:
      return "Unknown";
  }
}

useEffect(() => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can change this to 'auto' for instant scrolling
    });
}, []);

  useEffect(() => {
    async function fetchPlaces(countryId: string) {
      setIsLoading(true);    
      const result = await apiClient.getAllCountries(continent, false);

      let countries: FoundDto[] = result.map(item => {
        return {
          id: item.id,
          name: item.name,
          countryCode: item.countryCode,
          parentName: item.continentName,
          foundType: FoundType._0        
        }
      });
      setCountries(countries);
      setIsLoading(false);
    }

    fetchPlaces(continentNumber ?? '');
  }, [continentNumber]);

  return (
    <>
      <Helmet>
        <title>Up to visit</title>
        <meta name="description" content="Embark on a journey of discovery with our collection of destinations across the world's continents. From the bustling cities of Europe to the wilds of Africa and the stunning natural landscapes of Asia, our site provides inspiration and practical advice for travelers seeking unforgettable experiences. Start planning your next adventure today with our expertly curated guides to destinations on every continent" />
        <meta name="keywords" content="Europe travel destinations, Asia travel destinations, Africa travel destinations, North America travel destinations, South America travel destinations, Australia travel destinations, Adventure travel, City guides, Beach vacations, Cultural experiences, Nature tourism, Scenic views, Travel tips" />
      </Helmet>
      {isLoading && <LoadingOverlay />}
      <div>
        <div className="breadcrumb-header justify-content-between">
          <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">{continentName()}</span>
          </div>

          <div className="justify-content-center mt-2">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item className="breadcrumb-item tx-15" href={`/continents/${continentNumber}`}>
                Continent ({continentName()})
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="breadcrumb-item "
                active
                aria-current="page"
              >
                Country Places
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

        </div>
        {(countries && countries.length > 0) && <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {countries.map((item) => (
            <div key={item.id} className="place-item">
              <CountryThumbnail data={item} />
            </div>
          ))}
        </Masonry>}
        {(!countries || countries.length == 0) && <div className="text-center">No places found</div>}

      </div>
    </>
  );
};

export default Continent;